import { mapState } from "vuex";
import { defineComponent, onBeforeUnmount, ref } from "vue";
import { LeftOutlined, CheckCircleFilled, SyncOutlined, } from "@ant-design/icons-vue";
import { message } from "ant-design-vue";
import { loginValidate } from "@/hooks/component/loginValidate";
import { getDingScanUrl, getWechatTicketInfo, getWechatScanStatus, getWxworkScanUrl } from "@/apis";
import { renderH3Qr } from "@/uses/use-qr";
export default defineComponent({
    name: "H3Scan",
    components: {
        LeftOutlined,
        CheckCircleFilled,
        SyncOutlined,
    },
    props: {
        loginType: {
            type: String,
            default: "ding",
        },
    },
    mounted() {
        if (this.loginType === "ding") {
            this.getAuthUrl();
        }
        else if (this.loginType === 'wechat') {
            this.getWechatQrUrl();
        }
        else if (this.loginType === 'wxwork') {
            this.getWxworkQrUrl();
        }
    },
    computed: {
        ...mapState(["environment"]),
        isH3() {
            return this.environment === "h3";
        },
    },
    setup(_, context) {
        const { formState, rules, handleInput, handlePassword } = loginValidate();
        const wechatUrl = ref("");
        const wxworkUrl = ref("");
        const wechatcode = ref("");
        const step = ref("code");
        // 扫码状态
        const scanStatus = ref(0);
        // 定时器
        let timer = null;
        // 获取钉钉二维码链接
        async function getAuthUrl() {
            const ret = await getDingScanUrl();
            if (ret) {
                renderH3Qr(ret);
            }
        }
        // 获取企微二维码链接
        async function getWxworkQrUrl() {
            const ret = await getWxworkScanUrl({ hostUrl: location.origin });
            if (ret) {
                wxworkUrl.value = ret;
            }
        }
        // 跳转手机登录钩子函数
        function checkLoginType() {
            this.$emit("checkLoginType", "phone");
        }
        // 跳转注册
        function goScanRegister() {
            let url = `${window.location.origin}/scb/register.html#/register?type=ding`;
            const fromPath = this.$route.query.fromPath;
            if (fromPath) {
                url += `&fromPath=${fromPath}`;
            }
            window.location.replace(url);
        }
        // 登录事件
        function handleFinish() {
            context.emit("handleFinishWechat", {
                ...formState,
                wechatcode: wechatcode.value,
            });
        }
        // 轮询监测是否关注公众号/是否扫码
        async function getWechatLoginStatus(sceneId, sign) {
            const { Successful, ReturnData, ErrorMessage } = await getWechatScanStatus({ sceneId, sign });
            if (Successful) {
                scanStatus.value = ReturnData.Status;
                if (ReturnData.Status === 0) {
                }
                else if (ReturnData.Status === 1) {
                    clearInterval(timer);
                    // 若后台返回已绑定就调用微信登录接口 否则就跳转登录页，登录成功后自动绑定刚才扫的用户
                    if (ReturnData.Binded) {
                        context.emit("handleWechatLogin", ReturnData.Code);
                    }
                    else {
                        message.error("您的微信未绑定氚云账号，请登录账号并绑定");
                        setTimeout(() => {
                            step.value = "password";
                            wechatcode.value = ReturnData.Code;
                            scanStatus.value = 0;
                        }, 500);
                    }
                }
                else if (ReturnData.Status === -1) {
                    clearInterval(timer);
                }
            }
            else {
                message.error(ErrorMessage);
            }
        }
        // 获取微信二维码链接
        async function getWechatQrUrl() {
            // 清空定时器
            clearInterval(timer);
            timer = null;
            // 重置失效/扫码等状态
            scanStatus.value = 0;
            const { Successful, ReturnData, ErrorMessage } = await getWechatTicketInfo();
            if (Successful) {
                const data = { ...ReturnData };
                if (data.Ticket) {
                    wechatUrl.value = `https://mp.weixin.qq.com/cgi-bin/showqrcode?ticket=${data.Ticket}`;
                    timer = setInterval(() => {
                        getWechatLoginStatus(data.SceneId, data.Sign);
                    }, 3000);
                }
            }
            else {
                message.error(ErrorMessage);
            }
        }
        // 返回微信重新扫描
        function handleReturnWechat() {
            step.value = "code";
            getWechatQrUrl();
        }
        onBeforeUnmount(() => {
            clearInterval(timer);
            timer = null;
        });
        return {
            formState,
            rules,
            wechatUrl,
            wxworkUrl,
            step,
            scanStatus,
            handleInput,
            handlePassword,
            getAuthUrl,
            getWechatQrUrl,
            getWxworkQrUrl,
            checkLoginType,
            goScanRegister,
            getWechatLoginStatus,
            handleReturnWechat,
            handleFinish,
        };
    },
});

import { mapActions, mapState } from "vuex";
import { defineComponent, ref } from "vue";
import { useRoute } from "vue-router";
import { globalStorage } from "@/utils/storage/base";
import { message } from "ant-design-vue";
import { TokenKey, AutoLoginKey, setAuthCookie, setCookie2 } from "@/utils";
import { MobileRegexp, loginValidate, } from "@/hooks/component/loginValidate";
import { renewalToken, getRegisterInfo, } from "@/apis/index";
import H3Scan from "@/components/h3-scan.vue";
export default defineComponent({
    name: "LoginPass",
    components: {
        H3Scan,
    },
    data() {
        return {
            checked: false,
            counter: 0,
            iconKey: "",
            icons: [
                {
                    src: "https://static.h3yun.com/common-resource/login/svgs/dd-logo-white-new.svg",
                    alt: "钉钉",
                    size: 40,
                    key: "ding",
                    hoverSrc: "https://static.h3yun.com/common-resource/login/svgs/new-dd-logo-white.svg",
                },
                {
                    src: "https://static.h3yun.com/common-resource/login/svgs/new-wxwork-logo.svg",
                    alt: "企微",
                    size: 40,
                    key: "wxwork",
                    hoverSrc: "https://static.h3yun.com/common-resource/login/svgs/wxwork-logo-new.svg",
                },
            ],
            loginType: "phone",
            enumType: ["phone", "wxwork", "wechat", "ding"],
            loading: false,
            isShow: true,
        };
    },
    watch: {
        loginType: {
            immediate: true,
            handler: function (val) {
                // 用于区分企微、钉钉扫码
                window.sessionStorage.setItem("scanType", val);
            },
        },
    },
    computed: {
        ...mapState(["environment"]),
        isH3() {
            return this.environment === "h3";
        },
    },
    methods: {
        ...mapActions([
            "loginWithPassAction",
            "loginWithCorpAction",
            "loginWithWechatAction",
            "loginWithPassWechatAction",
        ]),
        // 密码登录（绑定微信）v1版本
        handleFinishWechat(values) {
            this.loading = true;
            this.loginWithPassWechatAction(values)
                .then((res) => {
                this.isShow = true;
                if (res.errcode === 0) {
                    this.handleCoration(res);
                }
                else {
                    message.error(res.errmsg || "系统异常");
                }
                this.loading = false;
            })
                .catch((errmsg) => {
                message.error(errmsg);
                this.loading = false;
            });
        },
        handleFinish(values) {
            this.loading = true;
            // todo 添加 loading 按钮
            // 获取人机信息串，并将其随业务请求一起传递至您的业务服务端
            window.nvc.getNVCValAsync((nvcVal) => {
                // const script = document.createElement("script");
                // script.src = "https://cf.aliyun.com/nvc/nvcAnalyze.jsonp?callback=registerRequest&a=" + nvcVal;
                // document.body.append(script);
                // this.isShow = false;
                // this.loading = false;
                this.handleLoginWithPass({ captcha: nvcVal });
            });
        },
        // 密码登录（绑定微信）v2版本
        handleLoginWithPass(params) {
            this.loginWithPassAction({
                mobile: this.formState.mobile,
                password: this.formState.password,
                wechatcode: this.formState.wechatcode,
                enginecode: this.formState.enginecode,
                ...params,
            })
                .then((res) => {
                this.isShow = true;
                if (res.errcode === 0) {
                    this.handleCoration(res);
                }
                else if (res.errcode === 202400) {
                    this.isShow = false;
                    window.nvc.getNC({ renderTo: "registerNC" });
                }
                else if (res.errcode === 200501 && this.formState.enginecode) {
                    // 从切换企业过来的页面 产品说要跳新页面提醒用户 咱刚来不敢说 也不敢问
                    location.href = `${window.location.origin}/error.html?errMessage=${res.errmsg}`;
                }
                else {
                    message.error(res.errmsg || "系统异常");
                    this.initAwsc("login", this.handleLoginWithPass);
                }
                this.loading = false;
            })
                .catch((errmsg) => {
                message.error(errmsg);
                this.loading = false;
            });
        },
        // 微信扫码登录
        handleWechatLogin(code) {
            this.loginWithWechatAction({ code })
                .then((res) => {
                this.handleCoration(res);
            })
                .catch((errmsg) => {
                message.error(errmsg);
            });
        },
        handleCoration(res) {
            const config = window.globalConfig;
            // 有多个企业让用户选择一个企业登录；只有一个企业就直接以该企业登录；如果没有企业，引导用户进入注册页。
            if (res.result.havecorps) {
                const corps = res.result.corps.map((elem) => ({
                    corpname: elem.Value,
                    corpcode: elem.Key,
                })) || [];
                globalStorage.setToLocal("select_token", res.result.token);
                globalStorage.setToLocal("select_corps", JSON.stringify(corps));
                const fromPath = this.$route.query.fromPath;
                this.$router.push({
                    name: "LoginSelectCorp",
                    query: {
                        fromPath,
                    },
                });
            }
            else if (res.result.enginecode && res.result.token) {
                setAuthCookie(res, "");
                window.location.replace(`${window.location.origin}/home.html?t=${new Date().getTime()}${res.result.enginecode ? `&enginecode=${res.result.enginecode}` : ``}`);
            }
            else {
                // 手机号未注册或已退出企业组织，请 注册 创建企业。
                if (this.counter > 0) {
                    return false;
                }
                this.counter = 1;
                const currElem = document.createElement("span");
                currElem.innerText = "注册";
                currElem.classList.add("link-register");
                currElem.addEventListener("click", () => {
                    this.gotoRegister();
                });
                const prevElem = document.createTextNode("手机号未注册或已退出企业组织，请 ");
                const nextElem = document.createTextNode(" 创建企业");
                const wrapper = document.createElement("div");
                wrapper.classList.add("link-register-wrapper");
                wrapper.appendChild(prevElem);
                wrapper.appendChild(currElem);
                wrapper.appendChild(nextElem);
                const mobile = this.$refs.mobile.$el;
                mobile && mobile.appendChild(wrapper);
            }
        },
        handleFailed(errors) {
            console.log(errors);
        },
        resetPassword() {
            this.$router.push({
                name: "ResetPassword",
                params: {
                    mobile: this.formState.mobile,
                },
            });
        },
        handleCheck(evt) {
            const checked = evt.target.checked;
            this.checked = checked;
            globalStorage.setToLocal(AutoLoginKey, checked);
        },
        initParams() {
            const mobile = this.$route.query.mobile;
            if (mobile && MobileRegexp.test(mobile)) {
                this.formState.mobile = mobile;
                this.formState.mobilePassed = true;
            }
        },
        gotoRegister() {
            // let url = `${window.location.origin}/scb/register.html#/register`;
            let url = `${window.location.origin}/entry/register`;
            const mobile = this.formState.mobile;
            const hasMobile = mobile && MobileRegexp.test(mobile);
            if (hasMobile) {
                url += `?mobile=${mobile}`;
            }
            const fromPath = this.$route.query.fromPath;
            if (fromPath) {
                url += `${hasMobile ? "&" : "?"}fromPath=${fromPath}`;
            }
            window.location.replace(url);
        },
        async getRegisterInfo() {
            const res = await getRegisterInfo();
            this.enableRegister = res.access;
        },
        checkLoginType(type) {
            this.loginType = type;
            const pathQuery = {
                ...this.$route.query,
                type,
            };
            this.$router.push({
                path: "login-pass",
                query: pathQuery,
            });
            this.iconKey = '';
        },
    },
    beforeRouteEnter(to, from, next) {
        const checked = globalStorage.getFormLocal(AutoLoginKey);
        const token = globalStorage.getFormLocal(TokenKey);
        const blackList = ["resister", "password"];
        const cover = blackList.indexOf(to.query.from) === -1;
        if (checked && token && cover) {
            // window.location.replace('http://localhost:5500/home.html');
            renewalToken({ token })
                .then((res) => {
                if (res.errcode === 0) {
                    // 更新 h3_token的值
                    setCookie2(TokenKey, res.result.token);
                    window.location.replace(`${window.location.origin}/home.html?t=${new Date().getTime()}`);
                }
                else {
                    setCookie2(TokenKey, "", -1);
                    globalStorage.removeFormLocal(TokenKey);
                    next();
                }
            })
                .catch(() => {
                next();
            });
        }
        else {
            next();
        }
    },
    created() {
        this.initParams();
        this.getRegisterInfo();
        const type = this.$route.query.type;
        // 若type不存在 默认phone 若存在则从type中取值,没有匹配上的默认phone 解决type=phone?q=/
        this.loginType = type
            ? this.enumType.indexOf(type) < 0
                ? "phone"
                : type
            : "phone";
        this.initAwsc("login", this.handleLoginWithPass);
    },
    setup() {
        const route = useRoute();
        const engineCode = route.query.engineCode ?? "";
        const { formState, rules, handleInput, handlePassword, initAwsc } = loginValidate();
        formState.enginecode = engineCode;
        const enableRegister = ref(false);
        // 按照之前的逻辑 进行判断 就不重新写了
        window.sessionStorage.setItem("origin_fromPath", route.query.fromPath ?? "");
        window.sessionStorage.setItem("templateCenter_redirect", route.query.redirectUrl ?? "");
        // 用于登录后自动选择企业
        window.sessionStorage.setItem("engineCode", engineCode);
        return {
            formState,
            rules,
            enableRegister,
            handleInput,
            handlePassword,
            initAwsc,
        };
    },
});

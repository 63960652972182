let tmpAuthUrl = '';
const handleMessage = (event) => {
    const { origin } = event;
    if (origin.indexOf('dingtalk') > -1) {
        const loginTmpCode = event.data; // 拿到loginTmpCode后就可以在这里构造跳转链接进行跳转了
        window.location.href = decodeURIComponent(tmpAuthUrl) + `&loginTmpCode=${loginTmpCode}`; // 跳转到授权链接
    }
};
const subscribe = () => {
    if (typeof window.addEventListener !== 'undefined') {
        window.addEventListener('message', handleMessage, false);
    }
    else if (typeof window.attachEvent !== 'undefined') {
        window.attachEvent('onmessage', handleMessage);
    }
};
const renderQr = (authUrl) => {
    tmpAuthUrl = authUrl;
    subscribe();
    window.DDLogin({
        id: 'ddlogin',
        goto: authUrl,
        style: '',
        href: '',
        width: '300px',
        height: '300px'
    });
};
const renderH3Qr = (authUrl, element = "ddlogin") => {
    tmpAuthUrl = authUrl;
    subscribe();
    window.DDLogin({
        id: element,
        goto: tmpAuthUrl,
        style: 'border:none;background-color: #fff;margin-top: -16px;',
        href: '',
        width: '',
        height: '290'
    });
};
export { renderQr, renderH3Qr };
